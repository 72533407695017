import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Col, Row } from 'react-bootstrap';
import config from '../../utilities/config.js';

class ChangeWaterRateView extends Component {

    months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    constructor(props) {
        let current = new Date();
        super(props);
        this.state = {
            loadedBuildings: false,
            loadedCWR: false,
            loadedCreatedDate: false,
            selectedItem: null,
            autocompleteBuildings : [],
            month: 0,
            day: 0,
            year: 0
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'ChangeWaterRate_view',
          });
        gtag('event','page_view');
        this.getallBuildings();
    }

    //Get all building information and load them it into the search box
    getallBuildings() {
        if(config.isAdmin == 1){
            let url = `${config.api}get-all-buildings`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    //const data = response["data"];
                    //console.log(data);
                    this.setState({
                        loadedBuildings: true,
                        autocompleteBuildings: response.data
                    });
                },
                    (error) => {
                        console.log(error);
                    }
                );
        }

        else{
                axios({
                    method: 'get',
                    url: `${config.api}organization/${config.orgID}/buildings`,
                    headers: { 
                        'Authorization': `Bearer ${config.token}`
                    }
                })
                .then((response) => {
                    //const data = response["data"];
                    //console.log(data);
                    this.setState({
                        loadedBuildings: true,
                        autocompleteBuildings: response.data
                    });
                },
                    (error) => {
                        console.log(error);
                    }
                ); 
        }



            // if(config.isAdmin == 1){
            //     console.log("ADMIN")
            //     axios({
            //         method: 'get',
            //         url: `${config.api}get-all-buildings`,
            //         headers: { 
            //             'Authorization': `Bearer ${config.token}`
            //         }
            //     })
            //     .then((response) => {
            //         const data = response["data"];
            //         this.getBuildingRows(data);
            //     })
            //     .catch((error) => {
            //         //console.log(error);
            //     });
            // }
    
            // else{
            //     console.log("GETTING BUILDINGS");
            //     axios({
            //         method: 'get',
            //         url: `${config.api}organization/${config.orgID}/buildings`,
            //         headers: { 
            //             'Authorization': `Bearer ${config.token}`
            //         }
            //     })
            //     .then((response) => {
            //         const data = response["data"];
            //         this.getBuildingRows(data);
            //     })
            //     .catch((error) => {
            //         //console.log(error);
            //     });
    
    
            // }


    }

    getCurrentWaterRate(buildingID) {
        //console.log("getCurrentWaterRate: " + buildingID)
        let url = `${config.api}buildings/${buildingID}/settings/cpg`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'text/plain'
                }
            }
        )
            .then((response) => {
                const data = response["data"];
                let cwr = "No water rate information.";

                if(data.length !== 0) {
                    cwr = data[0].costPerGallon;
                }

                this.setState({
                    currentWaterRate: cwr,
                    loadedCWR: true
                });
            },
                (error) => {
                    console.log(error);
                }
            );
    }

    createModifyForm(buildingLabel) {
        //console.log('createModifyForm was called: ' + buildingLabel);
        //console.trace();

        const { month, day, year } = this.state;
        //console.log("CMF: " + month + ':' + day + ':' + year)
        const currentYear = (new Date()).getFullYear();
        const years = Array.from({ length: currentYear - 2009 }, (_, i) => i + 2010); // All of the years from 2010 until the current
        const days = month && year ? Array.from({ length: this.daysInMonth(month, year) }, (_, i) => i + 1) : [];

        return (
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Building created date:</Form.Label>
                    <Col sm={10}>
                        {this.state.buildingCreatedDate}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Name:</Form.Label>
                    <Col sm={10}>
                        {buildingLabel}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Current Water Rate:</Form.Label>
                    <Col sm={2}>
                        {this.state.currentWaterRate}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>New Water Rate:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="newWaterRate" onChange={(e) => { this.state.newWaterRate = e.target.value; }}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Effective Date:</Form.Label>
                    <Col sm={10}>
                        <div>
                            <select defaultValue={this.state.month} onChange={e => this.setState({ month: e.target.value })}>
                                {this.months.map((month, index) => (
                                    <option key={month} value={1 + index}>
                                        {month}
                                    </option>
                                ))}
                            </select>
                            <select defaultValue={this.state.day} onChange={e => this.setState({ day: e.target.value })}>
                                {days.map(day => (
                                    <option key={day} value={day}>
                                        {day}
                                    </option>
                                ))}
                            </select>
                            <select defaultValue={this.state.year} onChange={e => this.setState({ year: e.target.value })}>
                                {years.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Col>
                </Form.Group>
                <Button variant="primary" onClick={this.updateWaterRate}>Update Water Rate</Button>&nbsp;
            </Form>
        )
    }

    updateWaterRate = () => {
        //console.log(this.state.selectedToChange);
        //console.log(this.state.newWaterRate);
        const year = this.state.year.toString();
        const month = this.state.month.toString().padStart(2, '0');
        const day = this.state.day.toString().padStart(2, '0');
        let effectiveDate = `"${year}-${month}-${day}"`; // The actual date that is going to be stored in the database
        let data = `[{ "cpg": ${this.state.newWaterRate}, "effectiveDate" : ${effectiveDate}, "sDate": "1970-01-01", "eDate": "2038-01-19" }]`;

        console.log(effectiveDate);
        console.log(this.selectedBuildingID);
        return;

        axios({
            method: 'post',
            url: `${config.api}building/${this.selectedBuildingID}/settings/cpg/recalculate`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            },
            data: data
        })
            .then((response) => {
                Swal.fire({
                    icon: 'success'
                })
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Could not update '
                })
            });
    }

    daysInMonth = (month, year) => {
        switch (month) {
            case 'February':
                return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28;
            case 'April':
            case 'June':
            case 'September':
            case 'November':
                return 30;
            default:
                return 31;
        }
    };

    getBuildingDate(item) {
        //console.log("getBuildingDate was called: " + item);
        let url = `${config.api}building/${item.id}/getEarliestDate`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        ).then((response) => {
            this.setState({ buildingCreatedDate: response.data[0].createdAt, selectedItem: item, loadedCreatedDate: true});
        })
    }

    render() {
        //console.log('Render was called');
        //console.log('Render: ' + this.state.year + ' ' + this.state.month + ' ' + this.state.day);

        // Show the search bar if all of the buildings are loaded and the UI is not currently loading data for a new selection
        if (this.state.loadedBuildings && ((this.selectedBuildingID === undefined) || (this.state.loadedCWR && this.state.loadedCreatedDate))) {
            Swal.close();
            return (
                <Container>
                    <h2>Change Building Water Rate</h2>
                    <Col style={{ marginTop: 12, padding: 12, marginBottom: 30 }}>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%',
                                zIndex: '999',
                            }}
                            items={this.state.autocompleteBuildings}
                            resultStringKeyName="label"
                            fuseOptions={{ keys: ["label"] }}
                            onSelect={(item) => {
                                let current = new Date();
                                this.setState(
                                    {
                                        loadedCWR : false,
                                        loadedCreatedDate: false,
                                        month: 1 + current.getMonth(),
                                        day: current.getDate(),
                                        year: current.getFullYear()
                                    }
                                );
                                this.getCurrentWaterRate(item.id);
                                this.getBuildingDate(item);
                                this.selectedBuildingID = item.id;
                            }}
                            maxResults={6}
                            showItemsOnFocus={true}
                            showNoResultsText="No Building found"
                            formatResult={(item) => {
                                return (
                                    <span>{item.label}</span>
                                )
                            }}
                        />
                    </Col>
                    {this.state.selectedItem && this.createModifyForm(this.state.selectedItem.label)}
                </Container>
            )
        }
        else {
            return (
                <LoadingView />
            )
        }
    }
}

export default withRouter(ChangeWaterRateView);