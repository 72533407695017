import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Col, Row } from 'react-bootstrap';

class AllBuildingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            tableRows: []
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'AllBuildings_view',
          })
        gtag('event','page_view')
        this.getallBuildings();
    }

    //Axios from DeviceRawData with config declared
    getallBuildings() {
        // Get all building IDs
        console.log("GETTING BUILDINGS");
        if(config.isAdmin == 1){
            console.log("ADMIN")
            axios({
                method: 'get',
                url: `${config.api}get-all-buildings`,
                headers: { 
                    'Authorization': `Bearer ${config.token}`
                }
            })
            .then((response) => {
                const data = response["data"];
                this.getBuildingRows(data);
            })
            .catch((error) => {
                //console.log(error);
            });
        }

        else{
            console.log("GETTING BUILDINGS");
            axios({
                method: 'get',
                url: `${config.api}organization/${config.orgID}/buildings`,
                headers: { 
                    'Authorization': `Bearer ${config.token}`
                }
            })
            .then((response) => {
                const data = response["data"];
                this.getBuildingRows(data);
            })
            .catch((error) => {
                //console.log(error);
            });


        }
    }

    // Original Method
    // getallBuildings() {
    //     //Get all building IDs
    //     let url = "https://api.flowactive.com/api/get-all-buildings";
    //     let token = localStorage.getItem("mlToken");

    //     axios.get(
    //         url,
    //         {
    //             headers: { Authorization: `Bearer ${token}` }
    //         }
    //       )
    //       .then((response) => {
    //         const data = response["data"];
    //         this.getBuildingRows(data);
    //       },
    //       (error) => {
    //         //console.log(error);
    //       }
    //     );
    // }

    getBuildingRows(allBuildings) {
         const buildingRows = allBuildings.map((item) => {
             const address = `${item.address}, ${item.city}, ${item.state} ${item.zip}`
             return (
                 <tr>
                     <td>{item.id}</td>
                     <td>{item.label}</td>
                     <td>{address}</td>
                     <td>{item.userID}</td>
                 </tr>
             );
         });
         this.setState({
             loaded: true,
             tableRows: buildingRows
         })
     }
    
    render() {
        if (this.state.loaded) {
            Swal.close()
            return (
                <Container style={{"height": "80%"}}>
                    <Row>
                    <h2>All Buildings</h2>
                    </Row>
                    <Row style={{"overflow": "scroll"}}>
                    <Table bordered hover striped responsive > 
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tableRows}
                        </tbody>
                    </Table>
                    </Row>
                    {/* <br /> */}
                </Container>
            );
        }
        else {
            return (
                <LoadingView />
            )
        }
        
    }
}

export default withRouter(AllBuildingsView);