import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import AllPropertiesTable from '../../components/Table/AllPropertiesTable/AllPropertiesTable';
import config from '../../utilities/config.js';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView';

var properties=new Array();

class AllPropertiesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'AllProperties_view',
          })
        gtag('event','page_view')
        this.getAllProperties();
    }

    getAllProperties() {
        //Get all building IDs
        if(config.isAdmin == 1){
            let url = `${config.api}get-all-properties`;



            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    const data = response["data"];
                    
                    data.forEach(data => {
                        properties.push({name: data.label, Orgid: data.organizationID, id: data.id})
                    });
                    this.setState({
                        loaded: true,
                    });

                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
        else{
            console.log("ORG ID "+ JSON.stringify(config.orgID));
            let url = `${config.api}organization/${config.orgID}/properties`;



            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    const data = response["data"];
                    
                    data.forEach(data => {
                        properties.push({name: data.label, Orgid: data.organizationID, id: data.id})
                    });
                    this.setState({
                        loaded: true,
                    });

                },
                    (error) => {
                        //console.log(error);
                    }
                );   
        }
            
    }

    render() {
        
        if (this.state.loaded) {
            Swal.close()
            return (
                <Container>
                    <h2>All Properties</h2>
                    <br />
                    <AllPropertiesTable properties={properties} />
                </Container>
            );
        }
        else {
            return (
                <LoadingView />
            )
        }
        
    }
}

export default withRouter(AllPropertiesView);