import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import moment from 'moment';
import './App.scss';
import config from '../utilities/config.js';
import Summary from './Summary/Summary';
import RoomView from './RoomView/RoomView';
import DeviceView from './DeviceView/DeviceView';
import LoginView from './LoginView/LoginView';
import MasqueradeView from './Tools/MasqueradeView';
import ProfileView from './ProfileView/ProfileView';
import AlertView from './AlertView/AlertView';
import ReportView from './ReportView/ReportView';
import StatsWidget from '../components/StatsWidget/StatsWidget';
import ToolsView from './ToolsView/ToolsView';
import LoginResetView from './LoginView/LoginResetView';
import RepairsView from './RepairsView/RepairsView';
import Dashboard from './DashboardView/DashboardView';
import WaterUseView from './WaterUseView/WaterUseView';
import TabViewTest from './TabViewTesting/TabViewTest';
import SetupView from './SetupView/SetupView';
import HelpView from './Help/HelpView';
import TempMaintenance from '../utilities/TempMaintenance';
import WaterWasteCalculatorView from './Tools/WaterWasteCalculatorView';
import MapView from './MapView/MapView';
import WiFiCredentials from './WiFiCredentials/WiFiCredentials';




class App extends Component {

  checkLoggedIn = () =>{
    let token = localStorage.getItem("mlToken");
    if(token != undefined && token != null){
      //User is logged in, set their config
      this.getUserConfig(token);
      if (config.production) {
        this.logActivity(token);
      }
      return true;
    }
    else{
      return false;
    }
  }

  getUserConfig(token) {
    //console.log("GETING USER CONFIG");
    config.token = token;
    let isActive = localStorage.getItem("mlIsActive");
    if (isActive != undefined && isActive != null) {
      config.isActive = isActive;
    }
    let dateRange = localStorage.getItem("mlDateRange");
    if (dateRange != undefined && dateRange != null) {
      config.dateRange = dateRange
    }
    const startDate = localStorage.getItem("mlStartDate");
    if (startDate != undefined && startDate != null) {
      config.startDate = startDate
    }
    else {
      config.startDate = moment().subtract(6 , 'days').format("YYYY-MM-DD");
    }
    const endDate = localStorage.getItem("mlEndDate");
    if (endDate != undefined && endDate != null) {
      config.endDate = endDate
    }
    else {
      config.endDate = moment().format("YYYY-MM-DD");
    }
    const selectedBuilding = localStorage.getItem("mlBuildingID")
    if (selectedBuilding != undefined && selectedBuilding != null) {
      config.selectedBuilding = selectedBuilding
    }
    let userID = localStorage.getItem("mlUserID");
    if (userID != undefined && userID != null) {
      config.userID = userID
    }
    let orgID = localStorage.getItem("mlOrgID");
    if (orgID != undefined && orgID != null) {
      config.orgID = orgID
    }
    let fName = localStorage.getItem("mlFName");
    if (fName != "undefined" && fName != null) {
      config.fName = fName
    }
    let lName = localStorage.getItem("mlLName");
    if (lName != "undefined" && lName != null) {
      config.lName = lName
    }
    let phone = localStorage.getItem("mlLPhone");
    if (phone != "undefined" && phone != null) {
      config.phone = phone
    }
    let isAdmin = localStorage.getItem("mlIsAdmin");
    if (isAdmin != undefined && isAdmin != null) {
      config.isAdmin = isAdmin
    }
    let isMasquerading = localStorage.getItem("mlIsMasquerading");
    if (isMasquerading != undefined && isMasquerading != null) {
      if (isMasquerading == "false") {
        config.isMasquerading = false;
      }
      else {
        config.isMasquerading = true;
      }
    }

    if(dateRange == 6){
      config.startDate = moment().subtract(6 , 'days').format("YYYY-MM-DD");
      config.endDate = moment().subtract(0 , 'days').format("YYYY-MM-DD");

    }
    else if(dateRange == 29){
      config.startDate = moment().subtract(29 , 'days').format("YYYY-MM-DD");
      config.endDate = moment().subtract(0 , 'days').format("YYYY-MM-DD");
    }
  }

  logActivity(token) {
    //get cur page
    const curPage = window.location.pathname;
    //get cur time
    const curTime = Date.now();

    var axios = require('axios');
    var data = null;
    if (config.isMasquerading == false || config.isMasquerading == "false") {
      data = `[{"userID": ${config.userID}, "userPage": "${curPage}", "userRequestTime": ${curTime}, "other": "{}"}]`;
    }
    else {
      const origID = localStorage.getItem("mlOriginalUser");
      data = `[{"userID": ${origID}, "userPage": "${curPage}", "userRequestTime": ${curTime}, "other": "{\\"Masquerade\\" : \\"${origID} masquerading as ${config.userID}\\"}"}]`;
    }

    var postConfig = {
      method: 'post',
      url: `${config.api}user-log`,
      headers: { 
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': "*",
        'Authorization': `Bearer ${token}`
      },
      data : data
    };

    axios(postConfig)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      //console.log(error);
    });

  }

  routes = () => {

    if(!this.checkLoggedIn()){
      return (
      <Router>
        <Switch>
          <Route path="/calculator" render={
                function(){
                  return <WaterWasteCalculatorView />
                }
              } 
          />
          <Route path="/login-reset" render = {
                function (){
                  return <LoginResetView />
                }
              }
              />
          <Route path="/" render={
                function(){
                  return <LoginView />
                }
              } 
          />
        </Switch>
      </Router>);
    }
    else if (config.isActive == 0) {
      return (
      <Router>
        <Switch>
        <Route path="/login-reset" render = {
                function (){
                  return <LoginResetView />
                }
              }
              />
          <Route path="/calculator" render={
                function(){
                  return <WaterWasteCalculatorView />
                }
              } 
          />
          <Route exact path="/logout" render={
              function(){
                return <LoginView />
              }
            }   
          />    
          <Route path="/" render={
                function(){
                  return <SetupView />
                }
              } 
          />
        </Switch>
      </Router>);
    }
    else{
      //console.log("LOGGED IN 2");
      return (
        <Router>
          <Switch>
          <Route path="/login-reset" render = {
                function (){
                  return <LoginResetView />
                }
              }
              />

            <Route exact path="/login" render={
                function(){
                  return <LoginView />
                }
              } 
            />

            <Route exact path="/logout" render={
                function(){
                  return <LoginView />
                }
              }   
            />    

            <Route exact path="/" render={
                function(){
                  return <Dashboard />
                }
              } 
            />

            <Route path="/alerts" render={
              function (){
                return <AlertView />
              }
            }
            />

            <Route exact path="/device-view" render={
                function(){
                  return <DeviceView />
                }
              } 
            />

            <Route exact path="/room-view" render={
                function(){
                  return <RoomView />
                }
              } 
            />   

{/*             <Route path="/repairs" render = {
                function () {
                  return <RepairsView />
                }
              }
            /> */}

            <Route path="/tools" render = {
                function () {
                  return <ToolsView />
                }
              }
            />



            <Route exact path="/profile" render={
                function (){
                  return <ProfileView />
                }
              }
            />

            <Route path="/pdfReport" render = {
              function () {
                return <ReportView />
              }
            }
            />

            <Route path="/widget" render = {
              function (){
                return <StatsWidget />
              }
            }
            />

            <Route exact path="/old" render={
                function(){
                  return <Summary />
                }
              } 
            />
              
            <Route path="/setup" render = {
                function (){
                  return <SetupView />
                }
              }
            />

            <Route  path="/help" render={
              function (){
                return <HelpView />
              }
            }
            />


            <Route path="/calculator" render={
                  function(){
                    return <WaterWasteCalculatorView />
                  }
                } 
            />

            <Route  path="/map-view" render={
              function (){
                return <MapView />
              }
            }
            />   

            <Route  path="/preconfigure" render={
              function (){
                return <WiFiCredentials />
              }
            }
            />   

            <Route  path="/" render={
              function (){
                return <Dashboard />
              }
            }
            />

         


          </Switch>

        </Router>
      
      );
    }
  }

  render() {
    return this.routes();
    // return <TempMaintenance />
  }
}

export default App;


